<template>
  <div class="result">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box" v-loading="loading">
        <div class="content-left">
          <el-table ref="multipleTable" :data="result.log_list" tooltip-effect="dark" style="width: 100%" @row-dblclick="rowDblclick">

            <el-table-column label="题号" align="center" width="55">
              <template slot-scope="scope">{{ scope.row.question_id }}</template>
            </el-table-column>
            <el-table-column label="题目名称" align="center" width="400">
              <template slot-scope="scope">
                <div class="btn" style="" v-html="scope.row.title"></div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="结果" align="center">
              <template slot-scope="scope">
                <div class="btn" style="">{{ scope.row.remark }}</div>
              </template>
              
            </el-table-column> -->
            <el-table-column label="结果" align="center">
              <template slot-scope="scope">
                <!-- <div class="submit-result" v-if="scope.row.type==1">
                  <div class="btn-result btnMsg">{{ scope.row.remark.label }}</div>
                  <div class="btn-result">{{ scope.row.remark.value }}</div>
                </div>
                <div v-if="scope.row.type==2">{{ scope.row.remark }}</div> -->
                <div class="submit-result" v-if="scope.row.type==1">
                  <div class="btn-result btnMsg" :style="{background:scope.row.remark.color}">{{ scope.row.remark.label }}</div>
                  <div class="btn-result" :style="{background:scope.row.remark.color}">{{ scope.row.remark.value }}</div>
                </div>
                <div v-if="scope.row.type!=1">{{ scope.row.remark.label }}</div>
              </template>
            </el-table-column>
            <el-table-column label="我的答案" align="center">
              <template slot-scope="scope">
               
                <el-tooltip effect="dark" :content="scope.row.answer" placement="top" v-if="scope.row.answer.length > 16">
                  <span class="ProblemList-name">{{ scope.row.answer.substring(0,16) + "..." }}</span>
                </el-tooltip>
                <span class="ProblemList-name" v-else>{{ scope.row.answer }}</span>
              </template>
            </el-table-column>
            <el-table-column label="正确答案" align="center">
              <template slot-scope="scope">
                <!-- <div class="btn" style="">{{ scope.row.right_answer }}</div> -->
                 <el-tooltip effect="dark" :content="scope.row.right_answer" placement="top" v-if="scope.row.right_answer.length > 16">
                  <span class="ProblemList-name">{{ scope.row.right_answer.substring(0,16) + "..." }}</span>
                </el-tooltip>
                <span class="ProblemList-name" v-else>{{ scope.row.right_answer }}</span>
              </template>
            </el-table-column>
            <el-table-column label="得分" align="center">
              <template slot-scope="scope">
                <div class="btn" style="">{{ scope.row.grade }}</div>
              </template>
            </el-table-column>
          </el-table>
          <!-- <div class="operation">
            <div class="bgGreen_btn" @click="lookDetails">返回题单</div>
          </div> -->

        </div>
        <div class="content-right">
          <div class="gradeNum">
            <p class="num">{{result.info.grade}}分</p>
            <p>提交于：{{result.info.last_commit_date}}</p>
          </div>
          <TopicList :noTopicList="false" :topicParam="topicParam"></TopicList>
          <div style="height:40px"></div>
          <HotRecommend></HotRecommend>
        </div>
      </div>

    </div>
    <el-backtop :bottom="100" :right="400" :visibility-height="400">
      <div class="go-top">
        <i class="el-icon-caret-top" style="color: #00957e"></i>
        <div style="margin-top: -8px;">顶部</div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
// 题目列表和知识点标签
import TopicList from "@/components/TopicList/TopicList.vue"
import HotRecommend from "@/views/api/com/hotRecommend";
import { answer_result, bisaianswer_result } from "@/api/problemList.js"
export default {
  components: {
    TopicList, HotRecommend,
  },
  data () {
    return {
      loading: false,
      tableData: [],
      id: '',
      result: {
        info: {
          grade: 0,
          last_commit_date: ''
        },
        log_list: []
      },
      topicParam: {
        topicList: [],
        knowledgeTag: []
      },
      examType: ''
    }
  },
  created () {
    this.id = this.$route.query.id
    this.examType = this.$route.query.type
    this.loading = true
    let api = answer_result
    if (this.examType == '比赛') {
      api = bisaianswer_result
    }
    api({ id: this.id }).then(({ data }) => {
      console.log(data, 'dadada')
      this.result = data
      this.topicParam.knowledgeTag = data.info.knows
      this.loading = false
    })
  },
  methods: {
    rowDblclick () { },
    lookDetails () {
      // this.$router.push({ path: '/problemList/overDetails', query: { id: this.id } })
      // this.$router.push({ path: '/problemList' })
    }

  }
}
</script>

<style lang="less" scoped>
.submit-result {
  display: flex;
  justify-content: center;
  color: #fff;
  .btnMsg {
    margin-right: 0.5rem;
  }
  .btn-result {
    border-right: 6px;
    // color: #fff;
    padding: 0 6px;
  }
}
.result {
  ::v-deep {
    .el-backtop {
      width: 56px;
      height: 56px;
    }
  }
  .go-top {
    .el-icon-caret-top {
      font-size: 26px;
      margin-top: -6px;
    }
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
  .box {
    display: flex;
    justify-content: space-between;
  }
  .content-left {
    width: 70%;
    .operation {
      text-align: right;
      margin-top: 30px;
    }
  }
  .content-right {
    width: 27%;
    .gradeNum {
      margin-bottom: 40px;
      text-align: center;
      .num {
        font-size: 55px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #303030;
        line-height: 77px;
        margin: 1rem 0;
      }
    }
  }
}
</style>